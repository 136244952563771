/* Default CSS */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

html,
body {
  height: 100%;
  width: 100%;
}

body {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  color: white;
}

button {
  background: transparent;
}

button:hover {
  color: #47126b;
  background: white;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 10px;
  background-color: #121212;
}

::-webkit-scrollbar-thumb {
  background: #282828; 
}

::-webkit-scrollbar-thumb:hover {
  background: #47126b; 
}

/* Heading Texts */
.blu {
  color: #97dffc;
}

.heading-text {
  text-align: center;
  font-size: 40px;
}

.subheading {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: -10px;
}

.subheading-text {
  text-align: center;
  font-weight: 300;
}

.line {
  width: 50px;
  height: 1.5px;
  background-color: #ffd500;
  border-radius: 1px;
}

/* Banner CSS */
div .bg {
  background-image: url("./assets/img/banner.png");
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  max-width: 100%;
}

.bg {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.banner-text {
  font-size: 1.75rem;
  margin: 0;
}

.banner-text,
.resume-button {
  margin-left: 7.5%;
}

.resume-download-form {
  width: 100%;
}

.resume-button {
  border-radius: 100px;
  color: white;
  border: 1px solid white;
  font-size: 1rem;
  font-weight: 300;
  margin-top: 1rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  transition: 0.4s;
}

.resume-button:hover {
  color: #47126b;
  cursor: pointer;
}

.resume-button .download {
  padding-right: .25rem;
}

/* About CSS */
.aboutme {
  background: linear-gradient(180deg, #170038, #47126b);
  padding-top: 8rem;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 8rem;
  flex-direction: row;
}

.aboutme img {
  max-width: 400px;
  width: 100%;
  height: auto;
  margin-left: 5rem;
  margin-right: 5rem;
  border-radius: 10px;
  box-shadow: 20px 20px black;
}

.container p {
  font-size: 18px;
  font-weight: 300;
  padding: .5rem;
}

.container p:first-child {
  font-weight: 500;
  text-decoration: underline;
  font-size: 30px;
}

/* Skills CSS */
.skills {
  background: linear-gradient(180deg, #47126b, #6411ad);
  padding: 8rem 5rem 2rem;
}

.skills-container {
  margin: 5rem;
  background-color: #121212;
  border-radius: 24px;
  padding: 2rem;
  box-shadow: 20px 20px black;
}

.grid-container {
  display: inline-grid;
  gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  margin-top: 2rem;
}

.card {
  background-color: #282828;
  border-radius: 10px;
}

.card h2 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .75rem;
  font-size: 25px;
  text-align: center;
  padding: 8% 2% 0;
}

.card ul {
  padding-left: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 1rem;
  font-size: 20px;
  font-weight: 300;
  margin: 1rem;
}

/* Experience CSS */
.experience {
  background: linear-gradient(180deg, #6411ad, #822faf, #973aa8, #ac46a1);
  padding: 8rem 5rem 2rem;
}

.experience-header {
  margin-bottom: 2rem;
}

.main-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 1rem;
}

.main-info-text {
  display: flex;
  flex-direction: column;
}

.experience #bu {
  height: 50px;
}

.experience #uob {
  height: 30px;
}

.experience #sis {
  height: 80px;
}

.experience h3 {
  display: flex;
  align-items: center;
  gap: .5rem;
}

.experience h4 {
  display: flex;
  align-items: center;
  gap: .5rem;
}

.experience a {
  text-decoration: none;
  color: #97dffc; 
  transition: 0.2s;
}

.experience a:hover {
  color: blue;
}

/* Projects CSS */
.projects {
  background: linear-gradient(180deg, #ac46a1, #d55d92);
  padding: 8rem 5rem 2rem;
}

.projects-grid {
  margin-top: 3rem;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  gap: 8rem;
  border-radius: 10px;
  align-items: start;
}

.projects-grid-item {
  background-color: #282828;
  padding: 10px;
  width: 450px;
  height: auto;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 10px 10px #121212;
}

.projects-grid-item img {
  height: auto;
  width: 400px;
  border: 5px solid #ffffff;
  margin-left: auto;
  margin-right: auto;
  margin: 10px;
}

.projects-grid-item span {
  display: inline-block;
  background-color: white;
  color: #282828;
  font-family: 'poppins';
  font-size: 1.25rem;
  width: auto;
  margin-right: 10px;
  padding: 0 5px;
  border-radius: 5px;
}

.projects-grid-item h2, p {
  margin: 5px 10px;
}

.projects-grid-item a {
  text-decoration: none;
  color: white;
  transition: 0.2s;
}

.projects-grid-item a:hover {
  opacity: 0.6;
}

/* Contact CSS */
.contact {
  background: linear-gradient(180deg, #d55d92, #ea698b, #ebd3d9);
  padding: 8rem 5rem 8rem;
}

.contact form {
  display: flex;
  flex-direction: column;
  margin: 1rem;
  align-items: center;
  justify-content: center;
}

.contact input, textarea{
  font-family: 'poppins';
  font-size: 18px;
  background: #282828;
  color: white;
  height: 4rem;
  width: 50%;
  margin: 0.25rem;
  padding: 1rem;
  border-radius: 12px;
  border: 0;
}

.contact textarea {
  height: 250px;
}
.contact input:placeholder-shown, textarea:placeholder-shown{
  font-style: italic;
}

.contact input[type=submit] {
  display: inline-block;
  width: 10%;
  background-color: #282828;
  height: 3rem;
  box-shadow: 8px 8px black;
  transition: 0.2s;
  padding: 0;
  font-weight: 700;
}

.contact input[type=submit]:hover {
  background-color: black;  
  cursor: pointer;
  box-shadow: 8px 8px #282828;
}

/* NavBar CSS */
.navbar {
  position: fixed;
  width: 100%;
  background-image: url("./assets/img/banner.png");
  z-index: 9999;
}

.navbar ul {
  height: 3.5rem;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.navbar li {
  font-weight: bold;
  font-size: 18px;
  text-decoration: none;
}

.navbar li:hover {
  cursor: pointer;
  opacity: 0.5;
}

/* Footer CSS */
.footer {
  background: linear-gradient(180deg, #ebd3d9, white);
  color: black;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.footer p {
  margin: 2rem 8rem;
  opacity: 0.8;
}

/* Media Query */
@media (max-width:1025px) {
  .aboutme {
    padding: 5rem;
  }

  .container {
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
  }

  .container img {
    margin-bottom: 2rem;
  }

  .skills-container {
    margin: 0;
  }

  .navbar ul {
    height: 4rem;
  }

  .navbar li {
    margin: 0 1rem;
    padding: 0;
    font-size: 16px;
  }

  .projects-grid {
    grid-template-columns: auto;
  }

  .contact input, textarea {
    margin: 0.5rem;
    width: 80%;
  }

  .contact input[type=submit] {
    width: 25%;
  }
}

@media (max-width:600px) {
  .aboutme {
    padding: 1rem;
  }

  .container img {
    width: 150px;
  }

  .container p {
    font-size: 12px;
  }

  .banner-text {
    font-size: 1.25rem;
  }

  .heading-text, .subheading-text {
    font-size: 25px;
  }

  .skills {
    padding: 0 2rem;
  }

  .skills-container {
    margin: 0;
  }

  .grid-container {
    grid-template-columns: auto;
    padding: 0;
  }

  .card h2 {
    font-size: 18px;
  }

  .card ul {
    font-size: 16px;
  }

  .experience {
    padding: 5rem 1rem 0;
    font-size: 10px;
  }

  .projects {
    padding: 5rem 1rem 0;
  }

  .projects-grid {
    margin-top: 1rem;
    grid-template-columns: auto;
    gap: 2rem;
  }

  .projects-grid-item {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;
  }

  .projects-grid-item img {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }

  .projects-grid-item span {
    font-size: 14px;
  }

  .contact {
    padding: 5rem 1rem 1rem;
  }

  .contact input, textarea {
    font-size: 16px;
    width: 100%;
  }

  .contact input[type=submit] {
    margin-top: 1rem;
    width: 50%;
    box-shadow: 5px 5px black;
  }

  .navbar ul {
    height: 2.5rem;
  }

  .navbar li {
    margin: 0;
    padding: 0;
    font-size: 10px;
  }

  .footer {
    padding: 2rem 0 1rem;
    flex-direction: column;
  }
  .footer p {
    margin: 0;
    font-size: 14px;
  }
}